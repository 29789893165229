import login from './components/login'
import container from './components/container'
import top from './components/top'
import menu from './components/menu'
import homesum from './components/homesum'
//频繁使用
import operate from './components/table/operate'
import pagination from './components/table/pagination'
import table from './components/table/table'
import tableColumn from './components/table/table.column'
import query from './components/query/query'
import queryItem from './components/query/query.item'
import dialog from './components/dialog/index'
//screen
import screenMainBox from './components/screen/mainbox'
import screenContentBox from './components/screen/contentbox'

//mixin
import GlobalOld from './config/global'


// element set
import ElementUI from './config/element';
import { setButtonClick } from './config/element';
var elementSize = 'medium';
if (window.$(window).width() < 1400) {
	elementSize = 'small';
}

const components = [
	login,
	container,
	top,
	menu,
	homesum,
	//频繁使用
	operate,
	pagination,
	table,
	tableColumn,
	query,
	queryItem,
	dialog,
	//screen
	screenMainBox,
	screenContentBox,
];

export default {
	install: function(Vue, option) {

		//mixin
		var Global = {
			...GlobalOld,
		};
		if (option && option.global) {
			Global = {
				...Global,
				...option.global
			};
		}
		var minxinObj = {
			data() {
				return {
					Global,
					global: Global
				}
			},
		};
		Vue.mixin(minxinObj);

		//components
		components.forEach(function(one) {
			if (one.name) {
				Vue.component(one.name, one);
			}
		});

		//埋点
		if (Vue.prototype.$collect) {
			let collect = Vue.prototype.$collect
			if (collect.buttonClick) {
				setButtonClick(collect.buttonClick,collect.option)
			}
		}

		//element set
		Vue.use(ElementUI)
		Vue.prototype.$ELEMENT = {
			size: elementSize,
			zIndex: 3000
		};
	}
}
