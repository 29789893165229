Date.prototype.Format = function(fmt) {
    var o = {
        'M+': this.getMonth() + 1, //月份   
        'd+': this.getDate(), //日   
        'h+': this.getHours(), //小时   
        'm+': this.getMinutes(), //分   
        's+': this.getSeconds(), //秒   
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度   
        'S': this.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
        }
    }
    return fmt;
};

//随机数
Math.stochastic = function(star, end) {
    var c = end - star;
    return Math.random() * c + star;
}

const util = {
    //获取链接 search 所带的参数
    getParam: function() {
        var q = location.search.substr(1);
        var qs = q.split('&');
        var arg = {};
        if (q && qs) {
            for (var i = 0; i < qs.length; i++) {
                arg[qs[i].substring(0, qs[i].indexOf('='))] = decodeURI(qs[i].substring(qs[i].indexOf('=') + 1)); //decodeURI 进行解码
            }
        }
        return arg;
    },
    //设置 title
    setTitle: function(title) {
        document.getElementsByTagName('title')[0].innerText = title;
    },
    //更改地址栏的请求地址面不刷新页面
    reHref: function(param, type, state) {
        var url = param;
        state = state ? state : {};
        if (!type || type === 'hash' || type === 0) {
            var href = location.href.replace(/#.*/, '');
            if (/^\//.test(url)) {
                url = '#' + url;
            } else if (/^\w*/.test(url)) {
                url = '#/' + url;
            }
            url = href + url;
        }
        try {
            window.history.replaceState(state, '', url);
        } catch (e) {
            console.error(e);
        }
    },
    //添加地址栏的请求地址面不刷新页面
    addHref: function(param, type, state) {
        var url = param;
        state = state ? state : {};
        if (!type || type === 'hash' || type === 0) {
            var href = location.href.replace(/#.*/, '');
            url = href + '#/' + param;
        }
        try {
            window.history.pushState(state, '', url);
        } catch (e) {
            console.error(e);
        }
    },
    //获取 localStorage 的缓存内容
    getStor: function(name) {
        var stor = localStorage.getItem(name);
        stor = JSON.parse(stor);
        return stor;
    },
    //设置 localStorage 的缓存内容
    setStor: function(name, param) {
        var stor = JSON.stringify(param);
        localStorage.setItem(name, stor);
    },
    //删除 localStorage 的缓存内容
    removeStor: function(name) {
        localStorage.removeItem(name);
    },
    //判断浏览器的类型
    isBrowser: function(type) {
        var ua = navigator.userAgent.toLowerCase();
        var value;
        switch (type) {
            case 'ios':
                value = /iphone|ipad|ipod/.test(ua);
                break;
            case 'android':
                value = /android/.test(ua);
                break;
            case 'wx':
                value = /micromessenger/.test(ua);
                break;
        }
        return value;
    },
}

export default util;
