const screen = {
    namespaced: true,
    state:()=>({
        appAlive:0
    }),
    mutations:{
        setAppAlive(state,val){
            state.appAlive = val
        }
    },
    getters:{
        appAlive(state){
            return state.appAlive
        }
    }
}

export default screen