<template>
    <div id="app">
        <jy-top :noTopRoute="noTopRoute" :logo="logo" @logout="logout" :rightText="rightText">
            <jy-menu :menu="nav" mode="horizontal" backgroundColor="#05265f" activeTextColor="#fff"></jy-menu>
        </jy-top>
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
</template>
<script>
import logo from '@/assets/title.png'
export default {
    data() {
        return {
            noTopRoute: ['login', 'center', 'centerV0', 'centerV1', 'centerV2', 'centerV3', 'adminLogin'],
            logo,
            nav: null,
        }
    },
    watch: {
        $route: {
            handler: function(to) {
                if (/^center/.test(to.name)) {
                    this.$util.setTitle('平台主页')
                } else {
                    this.$util.setTitle('支撑中心')
                }
            },
            immediate: true
        }
    },
    computed: {
        rightText: function() {
            let text = '欢迎'
            if (this.$store.state && this.$store.state.adminInfo) {
                text = this.$store.state.adminInfo.userName
            }
            return text
        },
    },
    methods: {
        logout() {
            var str = window.location.href
            var n = str.search(/admin/i);
            if (n != -1) {
                let url = '/adminLogout'
                this.$http.post(url).then(() => {
                    this.$util.removeStor('adminToken')
                    this.$store.state.adminInfo = null
                    window.location.reload()
                    // this.$router.push({ name: 'login' })
                })
            } else {
                let url = '/logout'
                this.$http.post(url).then(() => {
                    this.$util.removeStor('token')
                    this.$store.state.info = null
                    window.location.reload()
                    // this.$router.push({ name: 'login' })
                })
            }
        },
    },
}

</script>
<style lang="scss">
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

[class^='jy-'] {
    font-family: 'jy' !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.jy {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

</style>
