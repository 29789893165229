<template>
	<div class="jy_query_com">
		<el-form :label-width="formLebelWidth" :model="model" ref="queryForm">
			<el-row>
				<el-col :span="queryItemSpan">
					<el-row>
						<slot></slot>
					</el-row>
				</el-col>
				<el-col :span="buttonSpan">
					<el-form-item class="button_form_item" label-width="20px">
						<slot name="rightCol"></slot>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>
<script>
export default {
	name: 'jy-query',
	props: {
		buttonSpan: {
			type: [Number, String],
			default: 6
		},
		labelWidth: {
			type: [Number, String],
			default: '90px'
		},
		model: {
			type: Object,
			default: function() {
				return {};
			}
		},
	},
	computed: {
		queryItemSpan: function() {
			let buttonSpan = Number(this.buttonSpan) || 6;
			return 24 - buttonSpan;
		},
		formLebelWidth: function() {
			let n = Number(this.labelWidth.replace('px', '')) || 80;
			return n + 'px';
		},
	},
	methods: {
		resetFields() {
			this.$refs.queryForm.resetFields()
		},
	},
}

</script>
<style lang="scss">
.jy_query_com {
	.el-form-item {
		margin-bottom: 14px;
	}

	.button_form_item {
		text-align: right;

		*+.el-row {
			.el-button {
				margin-top: 14px;

			}
		}

	}

}

</style>
