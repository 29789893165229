import axios from 'axios';
import qs from 'qs';
import {
  Message,
  MessageBox
} from 'element-ui';
import Util from '@/common/js/util.js';
import Router from '@/router/';

var instance = axios.create({})

// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN
instance.defaults.baseURL = window.config.baseUrl;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // config.headers.token = Util.getStor('token');
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  return error;
});

var isLoginOut = false;
const defaultContentType = 'application/json';
const http = {

  /**
   * option:{
   *      type:'json' //数据类型
   *      baseUrl:'',
   *      err:function(err){}
   * }
   * 
   *  **/

  post: function (url, data, option) {
    let type = (option && option.type) || 'json'
    return new Promise(function (resolve, reject) {
      if (!data) {
        data = {}
      }
      data = typeof type == 'string' && type == 'format' ? qs.stringify(data) : data;

      let contentType = '';
      switch (type) {
        case 'json':
          contentType = 'application/json';
          break;
        case 'format':
          contentType = 'application/x-www-form-urlencoded';
          break;
        default:
          contentType = type;
      }

      let httpOption = {
        method: 'post',
        url,
        data,
        headers: {
          'content-type': contentType,
        },
      };

      if (typeof type == 'object') {
        httpOption = {
          ...httpOption,
          ...type,
          headers: {
            'content-type': defaultContentType
          },
        }
      }

      if (option && option.baseUrl) {
        httpOption.baseURL = option.baseUrl;
      }

      var str = window.location.href
      var n = str.search(/admin/i);
      let token = Util.getStor('token');
      if (token && n ==-1) {
        httpOption.headers.token = token;
      }

      let adminToken = Util.getStor('adminToken');
      if (adminToken && n !=-1) {
        httpOption.headers.adminToken = adminToken;
      }

      instance(httpOption).then((res) => {
        // console.log(res);
        //统一处理数据
        let data = res.data;
        if (data.code == '0') {
          resolve(data);
          if (isLoginOut) {
            isLoginOut = false;
          }
        } else if (data.code == window.config.loginOutCode) {
            console.log(window.location.href)
            var str = window.location.href
            var n = str.search(/admin/i);
            if(n!=-1){
                Util.removeStor('adminToken');
                if (!isLoginOut) {
                  isLoginOut = true;
                  MessageBox({
                    title: '提示',
                    message: '登录超时，请重新登录',
                    callback: () => {
                      Router.push({
                        name: 'adminLogin'
                      });
                    }
                  });
                }
            }else{
                Util.removeStor('token');
                if (!isLoginOut) {
                    isLoginOut = true;
                    MessageBox({
                    title: '提示',
                    message: '登录超时，请重新登录',
                    callback: () => {
                        Router.push({
                        name: 'login'
                        });
                    }
                    });
                }
            }
        } else {
          if (option && option.err) {
            option.err(data);
          } else {
            Message({
              message: data.error || data.message || data.msg,
              type: 'error'
            });
            reject(data);
          }
        }
      }).catch((error) => {
        // Message({
        //     message: error.message,
        //     type: 'error'
        // });
        reject(error);
      });
    })
  },
  // 表单下载
  form(url, params) {
    var turnForm = document.createElement('form');
    document.body.appendChild(turnForm);
    turnForm.method = 'post';
    turnForm.enctype = 'application/x-www-form-urlencoded';
    turnForm.action = window.config.baseUrl + url;
    turnForm.target = '_blank';
    //enctype
    for (let i in params) {
      if (params[i] || params == 0) {
        addInput(i, params[i]);
      }
    }

    function addInput(name, value) {
      var newElement = document.createElement('input');
      newElement.setAttribute('name', name);
      newElement.setAttribute('type', 'hidden');
      newElement.setAttribute('value', value);
      turnForm.appendChild(newElement);
    }
    console.log(turnForm)
    turnForm.submit();
    document.body.removeChild(turnForm);
  },
};

export default http
