import Vue from 'vue'
import Vuex from 'vuex'
import Http from '@/common/js/http'

import screen from './modules/screen.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info: null,
    adminInfo:null,
  },
  mutations: {
    setInfo(state, obj) {
      state.info = obj;
    },
    setAdminInfo(state, obj) {
        state.adminInfo = obj;
      },
  },
  actions: {
    getInfo({
      commit
    }, callback) {
      let url = '/sys/user/getUserInfo'
      Http.post(url, {}).then((data) => {
        console.log(window.location.href)
        var str = window.location.href
        var n = str.search(/admin/i);
        if(n!=-1){
            commit('setAdminInfo', data.detail);
        }else{
            commit('setInfo', data.detail);
        }
        callback && callback();
      }, () => {
        callback && callback();
      });
    },
  },
  modules: {
    screen:screen
  }
})
