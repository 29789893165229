import { render, staticRenderFns } from "./contentbox.vue?vue&type=template&id=2d1d1054&scoped=true&"
import script from "./contentbox.vue?vue&type=script&lang=js&"
export * from "./contentbox.vue?vue&type=script&lang=js&"
import style0 from "./contentbox.vue?vue&type=style&index=0&id=2d1d1054&prod&lang=scss&scoped=screen_common_block&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d1d1054",
  null
  
)

export default component.exports