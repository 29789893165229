<template>
	<el-dialog class="jy-dialog" v-bind="propsObj" :visible.sync="dialogVisible" @open="open" @opened="opened" @close="close" @closeed="closeed">
		<template slot="title">
			<span class="jy-dialog_title">{{title}}</span>
		</template>
		<div class="jy-dialog_body">
			<slot></slot>
		</div>
		<div slot="footer">
			<slot name="footer"></slot>
		</div>
	</el-dialog>
</template>
<script>
export default {
	name: 'jy-dialog',
	data() {
		return {
			dialogVisible: false,
		}
	},
	watch: {
		dialogVisible: function(val) {
			this.$emit('update:visible', val)
		},
		visible: function(val) {
			this.dialogVisible = val
		}
	},
	props: {
		type: {
			type: String,
			default: 'form'
		},
		title: {
			type: String,
			default: 'title'
		},
		visible: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: undefined
		},
		top: {
			type: String,
			default: '15vh'
		},
		fullscreen: {
			type: Boolean,
			default: undefined
		},
		modal: {
			type: Boolean,
			default: undefined
		},
		modalAppendToBody: {
			type: Boolean,
			default: undefined
		},
		appendToBody: {
			type: Boolean,
			default: undefined
		},
		lockScroll: {
			type: Boolean,
			default: undefined
		},
		closeOnClickModal: {
			type: Boolean,
			default: undefined
		},
		closeOnPressEscape: {
			type: Boolean,
			default: undefined
		},
		showClose: {
			type: Boolean,
			default: undefined
		},
		center: {
			type: Boolean,
			default: undefined
		},
		destroyOnClose: {
			type: Boolean,
			default: undefined
		},
		customClass: {
			type: String,
			default: 'dialog_form'
		},
		beforeClose: {
			type: Function,
			default: undefined
		},
	},
	computed: {
		comCustomClass: function() {
			let className = this.customClass
			switch (this.type) {
				case 'form':
				case 'formDouble':
					className = 'dialog_form'
					break
				case 'table':
					className = 'dialog_table'
					break
				default:
					className = 'dialog_form'
			}
			return className
		},
		comWidth: function() {
			let w = this.Global.dialogWidth;
			if (this.width !== undefined) {
				w = this.width
			} else {
				switch (this.type) {
					case 'form':
						w = this.Global.dialogWidth
						break
					case 'formDouble':
						w = this.Global.dialogDoubleWidth
						break
					case 'table':
						w = this.Global.dialogTableWidth || this.Global.dialogTabelWidth
						break
					default:
						w = this.Global.dialogWidth
				}
			}
			return w
		},
		propsObj: function() {
			let obj = {
				title: this.title,
				width: this.comWidth,
				top: this.top,
				fullscreen: this.fullscreen,
				modal: this.modal,
				modalAppendToBody: this.modalAppendToBody,
				appendToBody: this.appendToBody,
				lockScroll: this.lockScroll,
				closeOnClickModal: this.closeOnClickModal,
				closeOnPressEscape: this.closeOnPressEscape,
				showClose: this.showClose,
				center: this.center,
				destroyOnClose: this.destroyOnClose,
				customClass: this.comCustomClass,
				beforeClose: this.beforeClose
			}
			for (let key in obj) {
				if (obj[key] === undefined) {
					delete obj[key]
				}
			}
			return obj

		}
	},
	methods: {
		open() {
			this.$emit('open')
		},
		opened() {
			this.$emit('opened')
		},
		close() {
			this.$emit('close')
		},
		closeed() {
			this.$emit('closeed')
		},
	}
}

</script>
<style lang="scss">
.jy-dialog {
	.el-dialog__header {
		border-bottom: 1px solid #DCDFE6;

		.jy-dialog_title {
			font-size: 16px;
			color: #909090;
			letter-spacing: .6px;
		}
	}


	>.dialog_form {
		.el-dialog__body {
			padding-right: 100px;
			padding-bottom: 0;
		}


		.avatar-uploader {
			.el-upload {
				border: 1px dashed #DCDFE6;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
				width: 52px;
				height: 52px;
			}

			.avatar {
				width: 50px;
				height: 50px;
				display: inline-block;
			}

			.el-upload:hover {
				border-color: #409EFF;
			}
		}

		.avatar-uploader-icon {
			font-size: 28px;
			color: #8c939d;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
		}

	}

	>.dialog_table {
		.el-dialog__body {
			padding-right: 20px;
			padding-bottom: 0;
		}
	}
}

</style>
