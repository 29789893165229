<template>
	<div class="screen_main_box">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'jy-screen-main-box',
	props: {
		routeName: {
			type: String,
			default: 'screen',
		},
		bg: {
			type: String,
			default: ''
		},
	},
	watch: {
		'$route': {
			handler: function(to) {
				if (to.name == this.routeName) {
					this.setStyle();
					window.onresize = () => {
						this.setStyle();
					};
				} else {
					window.onresize = null;
					window.$('body').css({
						'width': '',
						'height': '',
						'transform': '',
						'transform-origin': ''
					});
				}
			},
			immediate: true,
		}
	},
	methods: {
		setStyle() {
			let win = {
				width: window.innerWidth,
				height: window.innerHeight,
			};
			var minWidth = 192*11
			var minHeight = 97*11

			let obj = {};
			obj = {
				background: 'url(' + this.bg + ') center center /cover'
			};

			if (win.width < minWidth) {
				let scaleX = win.width / minWidth;
				let scaleY = win.height / minHeight;
				obj = {
					width: minWidth + 'px',
					height: minHeight + 'px',
					background: 'url(' + this.bg + ') center center /cover',
				};
				this.$nextTick(() => {
					window.$('body').css({
						'width': minWidth + 'px',
						'height': minHeight + 'px',
						'transform': 'scale(' + scaleX + ',' + scaleY + ')',
						'transform-origin': 'top left'
					});
				});
			} else {
				window.$('body').css({
					'width': '',
					'height': '',
					'transform': 'scale(1，1)',
					'transform-origin': 'top left'
				});
			}
			this.homeStyle = obj;
		},
	},
}

</script>
<style lang="scss" scoped="screen_main_box">
.screen_main_box {
	background: #112077;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	color: #fff;
}

</style>
