//style
import '@/common/css/resect.css'
import '@/common/css/element-variables.scss'

import '@/common/js/config.js'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Util from '@/common/js/util.js'
Vue.prototype.$util = Util;

import Http from '@/common/js/http.js'
Vue.prototype.$http = Http;

Vue.config.productionTip = false

import jyui from '@/jyui/index'
Vue.use(jyui);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
