<template>
    <div class="jy-homeSum">
        <div class="homelayout">
            <div class="homeitem" v-for="(one) in list" v-bind:key="one.id" v-bind:class="{'hover':one.canClick===undefined?canClick:one.canClick}" v-on:click="itemClick(one)">
                <img :src="one.icon" class="img"/>
                <div class="text">
                    <div class="number">{{one.num}}</div>
                    <div>{{one.info}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'jy-homesum',
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default() {
                return []
            },
        },
        canClick:{
            type:Boolean,
            default:false
        },
    },
    methods:{
        itemClick(one){
            if(one.canClick===undefined?this.canClick:one.canClick){
                this.$emit('itemClick',one)
            }
        }
    },
}
</script>
<style lang="scss" scoped="jy-homeSum">
.jy-homeSum {
    box-shadow: #dcdcdc 0px 0px 7px;
    padding: 20px 20px;
    background: #fff;
    .homelayout {
        display: flex;
        flex-wrap: wrap;
        .homeitem {
            flex: 1;
            display: inline-flex;
            &.hover{
                cursor: pointer;
            }
            .img{
                height: 60px;
            }
            .text {
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                .number {
                    flex:1;
                    font-weight: bold;
                    font-size: 25px;
                    color:#5A5A5A;
                    + div {
                        color: #B3B8CE;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
            + .homeitem {
                border-left: 1px solid #ebebeb;
                padding-left: 20px;
            }
        }
    }
}
</style>


