import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'default',
    redirect: 'center',
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: () => import('../views/adminLogin')
  },
  {
    path: '/center',
    name: 'center',
    component: () => import('../views/center.v1')
  },
  {
    path: '/center-v0',
    name: 'centerV0',
    component: () => import('../views/center.v0')
  },
  {
    path: '/center-v1',
    name: 'centerV1',
    component: () => import('../views/center.v1')
  },
  {
    path: '/center-v2',
    name: 'centerV2',
    component: () => import('../views/center.v2')
  },
  {
    path: '/center-v3',
    name: 'centerV3',
    component: () => import('../views/center.v3')
  }, {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/entry.vue'),
    redirect: '/admin/home',
    children: [{
        path: 'home',
        name: 'home',
        component: () => import('../views/admin/home/home.vue'),
      },
      {
        path: 'application',
        name: 'application',
        component: () => import('../views/admin/application/application.vue'),
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/admin/user/user.vue'),
      },
      {
        path: 'log',
        name: 'log',
        component: () => import('../views/admin/log/entry.vue'),
        children: [{
            path: 'loginLog',
            name: 'loginLog',
            component: () => import('../views/admin/log/loginLog.vue')
          },
          {
            path: 'abnormalLogin',
            name: 'abnormalLogin',
            component: () => import('../views/admin/log/abnormalLogin.vue')
          },
          {
            path: 'operationLog',
            name: 'operationLog',
            component: () => import('../views/admin/log/operationLog.vue')
          },
        ]
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

const noTokenRouterNamList = ['login','adminLogin'];
router.beforeEach((to, from, next) => {
  //   let query = Util.getParam();
  //   if (query.token) {
  //     Util.setStor('token', query.token);
  //     let url = location.origin + location.pathname + '#/admin/home'
  //     location = url;
  //     return;
  //   }
  console.log(window.location.href)
  var str = window.location.href
  var n = str.search(/admin/i);
  if(n!=-1){
    var adminToken = window.localStorage.getItem('adminToken');
    if (!adminToken && !noTokenRouterNamList.includes(to.name)) {
        next('/adminLogin')
    } else {
        if (!store.state.adminInfo && adminToken) {
            store.dispatch('getInfo', () => {
                if (store.state.adminInfo && store.state.adminInfo.userName != 'admin' && to.name != 'admin') {
                next('/admin')
                } else {
                next()
                }
            });
        } else {
            if (store.state.adminInfo && store.state.adminInfo.userName != 'admin' && to.name != 'admin') {
                next('/admin')
            } else {
                next()
            }
        }
    }
  }else{
    var token = window.localStorage.getItem('token');
    if (!token && !noTokenRouterNamList.includes(to.name)) {
        next('/login')
    } else {
        if (!store.state.info && token) {
        store.dispatch('getInfo', () => {
            if (store.state.info && store.state.info.userName != 'admin' && to.name != 'center') {
            next('/center')
            } else {
            next()
            }
        });
        } else {
        if (store.state.info && store.state.info.userName != 'admin' && to.name != 'center') {
            next('/center')
        } else {
            next()
        }
        }
    }
  }
});
export default router
